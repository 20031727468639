<template>
    <div class="page-container">
        <div class="main-container">
            <div
                class="alert alert-danger mb-0"
                v-for="message in errorMessages"
                :key="message"
            >
                {{ message }}
                <button
                    class="btn btn-danger"
                    @click="dismissErrorMessage(message)"
                >
                    X
                </button>
            </div>

            <div v-if="!isLoading">
                <SubscriptionMinList
                    @play-content="handlePlayContentEvent"
                    :subscriptions="subscriptions"
                    @subscription-selected="handleSubscriptionSelected"
                    @download="handleDownload"
                    @download-delete="handleDownloadDelete"
                />
            </div>
            <div v-if="isLoading">Subscriptions are loading...</div>
        </div>
        <div class="footer-container">
            <div class="player-container">
                <ContentPlayer
                    v-show="playingContent"
                    :content="playingContent"
                    :progress="playerProgress"
                    :autoplay="autoplay"
                    :enableNextButton="true"
                    :enableAutoplayButton="true"
                    @player-save="handlePlayerSave"
                    @player-completed="handlePlayerCompleted"
                    @player-set-autoplay="handlePlayerAutoplay"
                    @player-next="handlePlayerNext"
                />
            </div>
        </div>
    </div>
</template>

<script>
import SubscriptionMinList, {
    SubscriptionMinListViewmodel,
} from "../components/SubscriptionMinList.vue";
import ContentPlayer, {
    ContentPlayerInputModel,
} from "../components/ContentPlayerMini.vue";
import SubscriptionApi from "../scripts/SubscriptionApi";
import { ContentItemViewmodel } from "../components/ContentMinList.vue";
import DownloadsRepository from "../scripts/DownloadsRepository";
import UserApi from "../scripts/UserApi";
import UserService from "../scripts/UserService";
import VersionApi from '../scripts/VersionApi';

const versionApi = new VersionApi();
const subApi = new SubscriptionApi();
const userApi = new UserApi();
const dlRepo = new DownloadsRepository();
const userService = new UserService();

export default {
    props: {},
    components: { ContentPlayer, SubscriptionMinList },
    data: () => ({
        /** @type {String[]} */
        errorMessages: [],
        /** @type {ContentPlayerInputModel} */
        playingContent: null,
        /** @type {Number} */
        playerProgress: null,
        /** @type {Boolean} */
        autoplay: false,
        showPlaylistControls: false,
        /** @type {SubscriptionMinListViewmodel[]} */
        subscriptions: [],
        /** @type {SubscriptionMinListViewmodel} */
        downloadSubscription: null,
        selectedSubscription: null,
        user: null,
        isLoading: true,
    }),
    async mounted() {
        //setup downloads sub manually
        let downloads = await dlRepo.getDownloadedList();
        let dlVms = downloads.map(
            (d) =>
                new ContentItemViewmodel(
                    d.title,
                    d.date,
                    d.description,
                    d.guid,
                    d.contentUrl,
                    d.rssUrl,
                    d.watched,
                    true,
                    d.progress || null,
                    d.totalDuration || null
                )
        );
        let downloadSub = new SubscriptionMinListViewmodel(
            "Downloads",
            "DOWNLOADS",
            "",
            false,
            true,
            dlVms.length,
            null,
            null,
            null,
            0,
            dlVms
        );
        downloadSub.content.push(...dlVms);
        downloadSub.hasLoadedBody = true;
        downloadSub.recalculateUnwatched();
        this.downloadSubscription = downloadSub;
        this.subscriptions.push(downloadSub);

        let subDtos = await subApi.getSubscriptions();
        if (subDtos == null) {
            //user token may have died, check user api
            let user = await userApi.getUser();
            if (user == null) {
                userService.clearUser();
                this.$router.go();
            } else {
                //it's a weird failure to get subscriptions, TODO handle better
            }
        }
        let vms = subDtos.map((d) => {
            let foundDownloaded = dlVms.filter((dl) => dl.rssUrl == d.url);
            return new SubscriptionMinListViewmodel(
                d.title,
                d.url,
                d.description,
                d.preferences.includeInFeed,
                d.preferences.showNotifications,
                d.unwatchedCount,
                d.index?.title,
                d.index?.guid,
                d.index?.url,
                d.allUnwatchedCount,
                foundDownloaded
            );
        });

        this.subscriptions.push(...vms);
        this.isLoading = false;

        let watchingContent = await subApi.getWatchingContent();
        if (watchingContent) {
            //check downloads first
            let foundDl = downloadSub.content.find(
                (c) =>
                    c.rssUrl == watchingContent.rssUrl &&
                    (c.title == watchingContent.title ||
                        c.guid == watchingContent.guid ||
                        c.url == watchingContent.url)
            );
            if (foundDl) {
                if (!foundDl.watched) {
                    await this.loadContentIntoPlayer(foundDl, false);
                    this.playerProgress = watchingContent.progress;
                }
            } else {
                let foundVm = vms.find(
                    (vm) => vm.url == watchingContent.rssUrl
                );
                if (foundVm) {
                    await foundVm.loadData();
                    let contentVm = foundVm.content.find(
                        (c) =>
                            c.title == watchingContent.title ||
                            c.guid == watchingContent.guid ||
                            c.url == watchingContent.url
                    );
                    if (contentVm && !contentVm.watched) {
                        await this.loadContentIntoPlayer(contentVm, false);
                        this.playerProgress = watchingContent.progress;
                    }
                }
            }
        }
    },
    methods: {
        /** @param {ContentItemViewmodel} content */
        async handleDownload(content) {
            content.downloading = true;
            let dlData = await dlRepo.download(
                content.title,
                content.guid,
                content.url,
                content.date,
                content.description,
                content.watched,
                content.rssUrl,
                content.progress,
                content.totalDuration,
            );
            content.downloading = false;
            if(dlData == null) {
                return;
            }
            content.downloaded = dlData;
            this.downloadSubscription.content.push(content);
            this.downloadSubscription.recalculateUnwatched();

            if (content.watching) {
                let downloadInfo = await dlRepo.getDownloadData(
                    content.title,
                    content.guid,
                    content.url,
                    content.rssUrl
                );
                if (downloadInfo) {
                    let downloadDataUrl = URL.createObjectURL(
                        downloadInfo.data
                    );
                    this.playingContent = new ContentPlayerInputModel(
                        this.playingContent.title,
                        this.playingContent.guid,
                        this.playingContent.url,
                        downloadDataUrl,
                        this.playingContent.rssUrl,
                        this.playingContent.playImmediately
                    );
                }
            }
        },
        /** @param {ContentItemViewmodel} content */
        async handleDownloadDelete(content) {
            content.downloaded = false;
            if (content.watching) {
                this.playingContent.url = content.url;
            }
            dlRepo.delete(
                content.title,
                content.guid,
                content.url,
                content.rssUrl
            );
            this.downloadSubscription.content =
                this.downloadSubscription.content.filter((c) => c.downloaded);
            this.downloadSubscription.recalculateUnwatched();

            if (content.watching) {
                this.playingContent = new ContentPlayerInputModel(
                    this.playingContent.title,
                    this.playingContent.guid,
                    this.playingContent.url,
                    null,
                    this.playingContent.rssUrl,
                    this.playingContent.playImmediately
                );
            }
        },
        /** @param {Number} progress */
        async handlePlayerSave(progress, totalDuration) {
            if (!this.playingContent) return;

            let foundContent = null;
            if (this.playingContent.downloadUrl) {
                foundContent = this.downloadSubscription.content.find(
                    (c) =>
                        c.url == this.playingContent.url ||
                        c.guid == this.playingContent.guid ||
                        c.title == this.playingContent.title
                );
            } else {
                /** @type {SubscriptionMinListViewmodel} */
                let foundRss = this.subscriptions.find(
                    (s) => s.url == this.playingContent.rssUrl
                );
                await foundRss?.loadData();
                foundContent = foundRss?.content?.find(
                    (c) =>
                        c.url == this.playingContent.url ||
                        c.guid == this.playingContent.guid ||
                        c.title == this.playingContent.title
                );
            }

            if (foundContent) {
                foundContent.progress = progress || 0;
                foundContent.totalDuration = totalDuration || 1;
                await subApi.setWatchingContent(
                    foundContent.rssUrl,
                    foundContent.title,
                    foundContent.url,
                    foundContent.guid,
                    foundContent.progress,
                    foundContent.totalDuration
                );
                if (foundContent.downloaded) {
                    await dlRepo.updateWatched(
                        foundContent.title,
                        foundContent.guid,
                        foundContent.url,
                        foundContent.rssUrl,
                        foundContent.watched,
                        foundContent.progress,
                        foundContent.totalDuration
                    );
                }
            }
        },
        async handlePlayerCompleted() {
            let sub = this.subscriptions.find(
                (s) => s.url == this.playingContent.rssUrl
            );
            await sub?.loadData();
            let contentVm = sub?.content.find(
                (c) =>
                    c.url == this.playingContent.url ||
                    c.guid == this.playingContent.guid ||
                    c.title == this.playingContent.title
            );
            sub?.recalculateUnwatched();

            if (contentVm) contentVm.watched = true;
            if (contentVm?.downloaded)
                this.downloadSubscription.recalculateUnwatched();

            await subApi.markContentWatched(
                this.playingContent.rssUrl,
                this.playingContent.title,
                this.playingContent.guid,
                this.playingContent.guid
            );
            await dlRepo.updateWatched(
                this.playingContent.title,
                this.playingContent.guid,
                this.playingContent.url,
                this.playingContent.rssUrl,
                true,
                0,
                contentVm?.totalDuration || 1
            );

            if (this.autoplay) await this.progressPlayerContent();
        },
        /** @param {Boolean} autoplay */
        handlePlayerAutoplay(autoplay) {
            this.autoplay = autoplay;
        },
        handlePlayerNext() {
            this.progressPlayerContent();
        },
        /** @param {SubscriptionMinListViewmodel} sub */
        handleSubscriptionSelected(sub) {
            this.selectedSubscription = sub;
        },
        /**
         * @param {ContentItemViewmodel} content
         */
        async handlePlayContentEvent(content) {
            await this.loadContentIntoPlayer(content, true);
        },

        async progressPlayerContent() {
            if (!this.playingContent) return;

            /** @type {SubscriptionMinListViewmodel[]} */
            let subscriptions = this.subscriptions;
            let contentSubscription = subscriptions.find(
                (s) => s.url == this.playingContent.rssUrl
            );
            await contentSubscription?.loadData();

            if (contentSubscription) {
                let index = contentSubscription.content.findIndex(
                    (c) =>
                        c.title == this.playingContent.title ||
                        c.url == this.playingContent.url ||
                        c.guid == this.playingContent.guid
                );

                let next = contentSubscription.content[index - 1];
                if (next) {
                    await this.loadContentIntoPlayer(next, this.autoPlay);
                } else {
                    await this.loadContentIntoPlayer(null, false);
                }
            }
        },

        /** @param {ContentItemViewmodel} content */
        async loadContentIntoPlayer(content, playImmediately) {
            //remove all isPlaying marks
            if (this.playingContent) {
                var prevSub = this.subscriptions.find(
                    (s) => s.url == this.playingContent.rssUrl
                );
                if (prevSub) {
                    await prevSub.loadData();
                    prevSub.isPlaying = false;

                    if (this.playingContent.downloadUrl) {
                        let prevContent =
                            this.downloadSubscription.content.find(
                                (c) =>
                                    c.url == this.playingContent.url ||
                                    c.guid == this.playingContent.guid ||
                                    c.title == this.playingContent.title
                            );
                        if (prevContent) prevContent.watching = false;
                    } else {
                        let prevContent = prevSub.content.find(
                            (c) =>
                                c.url == this.playingContent.url ||
                                c.guid == this.playingContent.guid ||
                                c.title == this.playingContent.title
                        );
                        if (prevContent) prevContent.watching = false;
                    }
                }
            }

            let playerInput = null;

            //set current isplaying marks, and attach any downloaded data before entering the player
            if (content) {
                let dlUrl = null;
                if (content.downloaded) {
                    let downloadedContent = await dlRepo.getDownloadData(
                        content.title,
                        content.guid,
                        content.url,
                        content.rssUrl
                    );
                    if (downloadedContent) {
                        if (
                            this.playingContent?.downloadUrl &&
                            this.playingContent.url ==
                                downloadedContent.contentUrl
                        )
                            dlUrl = this.playingContent.downloadUrl;
                        //prevent more data links attached to page
                        else
                            dlUrl = URL.createObjectURL(downloadedContent.data);
                    }
                }

                let sub = this.subscriptions.find(
                    (f) => f.url == content.rssUrl
                );
                if (sub) sub.isPlaying = true;

                content.watching = true;
                playerInput = new ContentPlayerInputModel(
                    content.title,
                    content.guid,
                    content.url,
                    dlUrl,
                    content.rssUrl,
                    playImmediately
                );

                this.playingContent = playerInput;
                this.playerProgress = content.progress;
            } else {
                this.playingContent = null;
                this.playerProgress = 0;
            }
        },
        dismissErrorMessage(message) {
            let index = this.errorMessages.indexOf(message);
            if (index > -1) {
                this.errorMessages.splice(index, 1);
            }
        },
    },
};
</script>

<style scoped>
/* .footer-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: white;
  max-height: 12em;
} */

/*.page-container {
    height: 100vh;
    width: 100vw;
}*/

.main-container {
    overflow-x: hidden;
}

.footer-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
    z-index: 1;
}

.player-container {
    border-top: 1px solid black;
}
</style>
