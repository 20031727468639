<template>
  <div class="">
    <div class="">
      <div class="">
        <div class="col-12" v-show="title">
          {{ title }}
        </div>

        <div class="col-12">
          <button v-show="enablePreviousButton" type="button" class="btn btn-lg btn-secondary mr-1" 
            @click="previousButtonPressed">
            <span class="fas fa-step-backward"></span>
          </button>



          <button v-show="enableAutoplayButton" class="btn btn-lg mr-1" :class="{
            'btn-outline-success': !autoplay,
            'btn-success': autoplay,
          }" @click="autoplayButtonPressed">
            <span class="far fa-play-circle"></span>
          </button>

          <button type="button" class="btn btn-lg btn-secondary mr-1 zoomfix" @click="skipBack">
            <span class="fas fa-undo-alt fa-hfix"></span>
          </button>

          <button type="button" class="btn btn-lg btn-outline-success mr-1 zoomfix" @click="playPauseToggle">
            <span class="fas fa-play" v-show="!isPlaying"></span>
            <span class="fas fa-pause" v-show="isPlaying"></span>
          </button>

          <button type="button" class="btn btn-lg btn-secondary mr-1 zoomfix" @click="skipForward">
            <span class="fas fa-redo-alt fa-hfix"></span>
          </button>

          <button v-show="enableNextButton" type="button" class="btn btn-lg btn-secondary mr-1"
            @click="nextButtonPressed">
            <span class="fas fa-step-forward"></span>
          </button>
        </div>
        <div class="col-12">
          <audio preload="none" style="width: 100%" @ended="handleContentCompleted" ref="audio" :autoplay="false"
            @emptied="handleStop" @pause="handleStop" @play="handlePlay" @durationchange="handleDurationChanged"
            @playing="handlePlay" @timeupdate="handleTimeUpdate">
            <source :src="url" />
          </audio>
          <div v-show="showTimestamp">
            {{ playerProgress }} / {{ duration }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export class ContentPlayerInputModel {
  /**
   * 
   * @param {String} title 
   * @param {String} guid 
   * @param {String} url 
   * @param {String} downloadUrl
   * @param {String} rssUrl 
   * @param {Boolean} playImmediately
   */
  constructor(title, guid, url, downloadUrl, rssUrl, playImmediately) {
    this.title = String(title);
    this.guid = String(guid);
    this.url = String(url);
    this.downloadUrl = downloadUrl && String(downloadUrl);
    this.rssUrl = String(rssUrl);
    this.playImmediately = Boolean(playImmediately);
  }
}

function formatTime(seconds) {
  let totalSeconds = seconds ?? 0;
  let h = Math.floor(totalSeconds / 3600);
  let m = Math.floor(totalSeconds / 60) % 60;
  let s = Math.floor(totalSeconds) % 60;
  return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
}

export default {
  props: {
    content: ContentPlayerInputModel,
    progress: Number,
    autoplay: Boolean,

    enableAutoplayButton: {
      type: Boolean,
      default: false
    },
    enableNextButton: {
      type: Boolean,
      default: false
    },
    enablePreviousButton: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      title: "",
      url: "",
      isPlaying: false,
      duration: 0,
      playerProgress: 0,
      showTimestamp: false,
      lastSavedProgress: 0,
    };
  },
  computed: {
  },
  mounted() {
    this.isPlaying = this.autoplay;
  },
  watch: {
    /** @param {ContentPlayerInputModel} val */
    content(val, old) {
      if (val) {
        let preservedProgress = null;
        if(old && val.rssUrl == old.rssUrl && (val.title == old.title || val.guid == old.guid || val.url == old.url))
          preservedProgress = this.$refs.audio.currentTime;

        this.title = val.title;
        this.url = val.downloadUrl || val.url;
        this.$refs.audio.load();
        this.isPlaying = val.playImmediately;
        if (val.playImmediately)
          this.$refs.audio.play();

        //guard against race condition that can change underlying content before the load/play has been completed on this run
        if(preservedProgress && val.rssUrl == this.content.rssUrl && (val.title == this.content.title || val.guid == this.content.guid || val.url == this.content.url))
        {
          this.$refs.audio.currentTime = preservedProgress;
          this.lastSavedProgress = preservedProgress;
        }
        else
        {
          this.lastSavedProgress = 0;
          this.$refs.audio.currentTime = 0;
        }
        this.handleTimeUpdate();
        this.handleDurationChanged();
      } else {
        this.title = "";
        this.url = "";
        this.$refs.audio.pause();
      }
    },
    /** @param {Number} val */
    progress(val) {
      this.lastSavedProgress = val;
      this.$refs.audio.currentTime = val;
    },
  },
  methods: {
    skipBack() {
      this.$refs.audio.currentTime -= 10;
      this.lastSavedProgress = this.$refs.audio.currentTime;
    },
    skipForward() {
      this.$refs.audio.currentTime += 30;
      this.lastSavedProgress = this.$refs.audio.currentTime;
    },
    handleContentCompleted() {
      this.$emit("player-completed");
    },
    autoplayButtonPressed() {
      this.$emit("player-set-autoplay", !this.autoplay);
    },
    nextButtonPressed() {
      this.$emit("player-next");
    },
    previousButtonPressed(){
      this.$emit('player-previous');
    },
    handleDurationChanged(e) {
      this.duration = formatTime(this.$refs.audio.duration);
      this.showTimestamp = !!this.$refs.audio.duration;
    },
    handleTimeUpdate(e) {
      this.playerProgress = formatTime(this.$refs.audio.currentTime);
      if(this.$refs.audio.currentTime - this.lastSavedProgress > 30) {
        this.lastSavedProgress = this.$refs.audio.currentTime;

        let progress = Math.round(this.$refs.audio.currentTime);
        let totalDuration = Math.round(this.$refs.audio.duration);
        this.$emit("player-save", progress, totalDuration);
      }
    },
    playPauseToggle() {
      this.isPlaying = !this.isPlaying;
      if (this.isPlaying) {
        this.$refs.audio.play();
      } else {
        this.$refs.audio.pause();
      }
    },
    handleStop() {
      this.isPlaying = false;
    },
    handlePlay() {
      this.isPlaying = true;
    },
  },
};
</script>

<style>
.zoomfix {
  touch-action: none;
}
</style>