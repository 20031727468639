import Vue from 'vue'
import VueRouter from 'vue-router'

//utils
import UserService from '../scripts/UserService.js'
import VersionChecker from '../scripts/VersionChecker.js'
import SubscriptionStore from '../scripts/SubscriptionStore';

//views
import UserSelect from '../views/UserSelect.vue'
import MinView from '../views/MinView.vue'
import SleepView from '../views/SleepView.vue'
import SubscriptionsView from '../views/SubscriptionsView.vue'

const store = new SubscriptionStore();

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: SubscriptionsView,
    props: { store: store }
  },
  {
    path: '/login',
    name: 'Login',
    component: UserSelect
  },
  {
    path: '/sleep',
    name: 'Sleep',
    component: SleepView
  },
  {
    path: '/legacy',
    name: 'legacy view',
    component: MinView,
  }
]

const router = new VueRouter({
  routes
});

const userService = new UserService();
const versionChecker = new VersionChecker();
var hasValidatedLogin = false;

router.beforeEach(async (to, from, next) => {

  versionChecker.ensureCorrectVersion();

  let user = userService.getLoggedInUser();

  if (user && !hasValidatedLogin) {
    let isValidLogin = await userService.validateLogin();
    if (!isValidLogin) {
      userService.clearUser();
      user = null;
    } else {
      hasValidatedLogin = true;
    }
  }

  if (to.name !== 'Login' && !user) {
    //sick of seeing that redirect query if I'm not planning on redirecting
    if (to.name != 'Home')
      next({ name: 'Login', query: { redirect: to.path } });
    else
      next({ name: 'Login' });
  } else if (to.name === 'Login' && user) {
    userService.clearUser();
    next();
  } else {
    next();
  }
})

export default router
