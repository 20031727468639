<template>
    <div>
        <h1>
            {{ subscription.title }}
        </h1>
        <div
            :class="{ 'description-collapsed': !descriptionExpanded }"
            @click="descriptionExpanded = !descriptionExpanded"
        >
            <p v-html="subscription.description"></p>
            <div
                v-if="!['DOWNLOAD', 'MAIN_FEED'].includes(subscription.rssUrl)"
                class="mb-2"
            >
                <button
                    @click.stop="handleIncludeInFeedButtonPressed"
                    class="btn btn-sm"
                    :class="{
                        'btn-outline-success': subscription.includeInFeed,
                        'btn-outline-danger': !subscription.includeInFeed,
                    }"
                >
                    <span
                        v-show="subscription.includeInFeed"
                        class="fa fa-check"
                    ></span>
                    <span
                        v-show="!subscription.includeInFeed"
                        class="fa fa-times"
                    ></span>
                    main feed
                </button>
                <button
                    @click.stop="handleShowNotificationButtonPressed"
                    class="btn btn-sm ml-2"
                    :class="{
                        'btn-outline-success': subscription.showNotifications,
                        'btn-outline-secondary': !subscription.showNotifications,
                    }"
                >
                    <span
                        v-show="subscription.showNotifications"
                        class="fa fa-check"
                    ></span>
                    <span
                        v-show="!subscription.showNotifications"
                        class="fa fa-times"
                    ></span>
                    show notifications
                </button>
            </div>
        </div>
        <button
            v-show="descriptionExpanded"
            class="btn btn-sm btn-block btn-outline-secondary"
            @click="descriptionExpanded = false"
        >
            Collapse
        </button>
        <div>
            <ul class="list-group">
                <li
                    class="content-list-item list-group-item one-line text-left progress-container"
                    v-for="content in contents"
                    :key="content.guid"
                    :class="{
                        'list-group-item-secondary': content.watched,
                        'list-group-item-success': content.watching,
                    }"
                >
                    <div class="row">
                        <div class="col-auto">
                            <button
                                :disabled="content.downloading"
                                v-show="!content.downloaded"
                                @click="downloadButtonPressed(content)"
                                class="btn btn-sm btn-success"
                            >
                                <span class="fas fa-save fa-hfix"></span>
                            </button>
                            <button
                                :disabled="content.downloading"
                                v-show="content.downloaded"
                                @click="deleteButtonPressed(content)"
                                class="btn btn-sm btn-danger"
                            >
                                <span class="fas fa-trash-alt fa-hfix"></span>
                            </button>
                        </div>

                        <div class="col row">
                            <div class="col-12 col-md-auto">
                                {{ content.title }}
                                <br />
                                {{ content.date.toLocaleDateString() }}
                            </div>

                            <div
                                class="text-muted text-right col-md col-12"
                                v-show="content.rssUrl != subscription.rssUrl"
                            >
                                {{ content.subscription.title }}
                            </div>
                        </div>

                        <div class="col-auto">
                            <button
                                @click="playButtonPressed(content)"
                                class="btn btn-sm btn-success"
                            >
                                <span class="fas fa-play"></span>
                            </button>
                            <button
                                :disabled="content.downloading"
                                @click="watchedButtonPressed(content)"
                                class="btn btn-sm btn-outline-secondary"
                            >
                                <span
                                    class="fas fa-check fa-hfix"
                                    v-show="!content.watched"
                                ></span>
                                <span
                                    class="fas fa-times fa-hfix"
                                    v-show="content.watched"
                                ></span>
                            </button>
                        </div>

                        <div
                            class="progress bg-success"
                            v-show="!content.watched && content.progress"
                            :style="{
                                width:
                                    (content.progress / content.duration) *
                                        100 +
                                    '%',
                            }"
                        ></div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="spacer"></div>
    </div>
</template>

<script>
import SubscriptionStore, {
    SubscriptionModel,
} from "../scripts/SubscriptionStore";

export default {
    props: {
        subscription: SubscriptionModel,
    },
    data: () => ({
        descriptionExpanded: false,
        contents: [],
    }),
    async mounted() {
        this.contents = await this.subscription.content;
    },
    methods: {
        handleIncludeInFeedButtonPressed() {
            this.$emit("toggle-includeinfeed");
        },
        handleShowNotificationButtonPressed() {
            this.$emit("toggle-shownotifications");
        },
        downloadButtonPressed(content) {
            this.$emit("download", content);
        },
        deleteButtonPressed(content) {
            this.$emit("download-delete", content);
        },
        playButtonPressed(content) {
            this.$emit("play-content", content);
        },
        watchedButtonPressed(content) {
            this.$emit("toggle-contentwatched", content);
        },
    },
};
</script>

<style>
.spacer {
    min-height: 12em;
}
</style>