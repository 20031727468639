import axios from "axios";

export class SubscriptionListDto {
    /**
     * 
     * @param {String} url 
     * @param {String} title 
     * @param {String} description 
     * @param {Number} unwatchedCount 
     * @param {Boolean} includeInFeed 
     * @param {Boolean} showNotifications 
     * @param {String} indexTitle 
     * @param {String} indexGuid 
     * @param {String} indexUrl 
     * @param {Number} allUnwatchedCount
     */
    constructor(url, title, description, unwatchedCount, includeInFeed, showNotifications, indexTitle, indexGuid, indexUrl, allUnwatchedCount) {
        this.url = String(url);
        this.title = String(title);
        this.description = String(description)
        this.unwatchedCount = Number(unwatchedCount);
        this.allUnwatchedCount = Number(allUnwatchedCount);
        this.preferences = {
            includeInFeed: Boolean(includeInFeed),
            showNotifications: Boolean(showNotifications),
        };
        this.index = {
            title: String(indexTitle),
            guid: String(indexGuid),
            url: String(indexUrl),
        }
    }
}

export class ContentListDto {
    /**
     * 
     * @param {String} url 
     * @param {String} title 
     * @param {String} description 
     * @param {String} guid 
     * @param {Date} date 
     * @param {Boolean} watched 
     * @param {Number} progress
     * @param {Number} totalDuration
     * @param {String} rssUrl
     */
    constructor(url, title, description, guid, date, watched, progress, totalDuration, rssUrl) {
        this.url = String(url);
        this.title = String(title);
        this.description = String(description);
        this.guid = String(guid);
        this.date = new Date(date);
        this.watched = Boolean(watched);
        this.progress = Number(progress);
        this.totalDuration = Number(totalDuration);
        this.rssUrl = String(rssUrl);
    }
}

export class WatchingContentDto {
    /**
     * 
     * @param {String} rssUrl 
     * @param {String} url 
     * @param {String} title 
     * @param {String} guid 
     * @param {Number} progress
     * @param {Number} totalDuration
     */
    constructor(rssUrl, url, title, guid, progress, totalDuration) {
        this.rssUrl = String(rssUrl);
        this.url = String(url);
        this.title = String(title);
        this.guid = String(guid);
        this.progress = Number(progress);
        this.totalDuration = Number(totalDuration);
    }
}

export default class SubscriptionApi {
    /**
     * GET /rss/api/v2/subscriptions
     * @returns {SubscriptionListDto[]}
     */
    async getSubscriptions() {
        try {
            let response = await axios.get('rss/api/v2/subscriptions');
            if (response.data) {
                return response.data.map(dto => new SubscriptionListDto(dto.url, dto.title, dto.description, dto.unwatchedCount, dto.preferences?.includeInFeed, dto.preferences?.showNotifications, dto.index?.title, dto.index?.guid, dto.index?.url, dto.allUnwatchedCount));
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    }

    /**
     * POST /rss/api/v2/subscriptions
     * @param {String} url 
     * @returns {Boolean}
     */
    async addSubscription(url) {
        try {
            let response = await axios.post('rss/api/v2/subscriptions?rssUrl=' + encodeURIComponent(url));
            if (response.status == 204) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    }

    /**
     * Delete /rss/api/v2/subscriptions
     * @param {String} url 
     * @returns {Boolean}
     */
    async removeSubscription(url) {
        try {
            let response = await axios.delete('rss/api/v2/subscriptions?rssUrl=' + encodeURIComponent(url));
            if (response.status == 204) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    }

    /**
     * GET /rss/api/v2/subscriptions/content
     * @param {String} url 
     * @returns {ContentListDto[]}
     */
    async getContent(url) {
        try {
            let response = await axios.get('rss/api/v2/subscriptions/content?rssUrl=' + encodeURIComponent(url));
            if (response.data) {
                return response.data.map(d => new ContentListDto(d.url, d.title, d.description, d.guid, d.date, d.watched, d.progressSeconds, d.totalSeconds, d.rssUrl));
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    }

    /**
     * GET /rss/api/v2/subscriptions/content/feed
     * @returns {ContentListDto[]}
     */
    async getMainFeedContent() {
        try {
            let response = await axios.get('rss/api/v2/subscriptions/content/feed');
            if (response.data) {
                return response.data.map(d => new ContentListDto(d.url, d.title, d.description, d.guid, d.date, d.watched, d.progressSeconds, d.totalSeconds, d.rssUrl));
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    }

    /**
     * PUT /rss/api/v2/subscriptions/preferences
     * @param {String} url 
     * @param {Boolean} includeInFeed 
     * @param {Boolean} showNotifications 
     * @returns {Boolean}
     */
    async updatePreferences(url, includeInFeed, showNotifications) {
        try {
            let response = await axios.put('rss/api/v2/subscriptions/preferences?rssUrl=' + encodeURIComponent(url), {
                includeInfeed: includeInFeed,
                showNotifications: showNotifications
            });
            if (response.status == 204) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    }

    /**
     * PUT /rss/api/v2/subscriptions/index
     * @param {String} url 
     * @param {String} title 
     * @param {String} guid 
     * @param {String} contentUrl 
     * @returns {Boolean}
     */
    async updateIndex(url, title, guid, contentUrl) {
        try {
            let response = await axios.put('rss/api/v2/subscriptions/index?rssUrl=' + encodeURIComponent(url), {
                title: title,
                guid: guid,
                url: contentUrl
            });
            if (response.status == 204) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    }

    /**
     * PUT /rss/api/v2/subscriptions/content/watched
     * @param {String} rssUrl 
     * @param {String} title 
     * @param {String} guid 
     * @param {String} contentUrl 
     */
    async markContentWatched(rssUrl, title, guid, contentUrl) {
        try {
            let response = await axios.put('rss/api/v2/subscriptions/content/watched?rssUrl=' + encodeURIComponent(rssUrl), {
                url: contentUrl,
                guid: guid,
                title: title
            });
            if (response.status == 204) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    }

    /**
     * DELETE /rss/api/v2/subscriptions/content/watched
     * @param {String} rssUrl
     * @param {String} title 
     * @param {String} guid 
     * @param {String} contentUrl 
     */
    async markContentUnWatched(rssUrl, title, guid, contentUrl) {
        try {
            let response = await axios.delete('rss/api/v2/subscriptions/content/watched?rssUrl=' + encodeURIComponent(rssUrl), {
                data: {
                    url: contentUrl,
                    guid: guid,
                    title: title
                }
            });
            if (response.status == 204) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    }

    /**
     * GET /rss/api/v2/subscriptions/content/watching
     */
    async getWatchingContent() {
        try {
            let response = await axios.get('rss/api/v2/subscriptions/content/watching');
            if (response.data) {
                return new WatchingContentDto(response.data.rssUrl, response.data.url, response.data.title, response.data.guid, response.data.progressSeconds, response.data.totalSeconds);
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    }

    /**
     * PUT /rss/api/v2/subscriptions/content/watching
     * @param {String} rssUrl 
     * @param {String} title 
     * @param {String} url 
     * @param {String} guid 
     * @param {Number} progress 
     */
    async setWatchingContent(rssUrl, title, url, guid, progress, totalDuration) {
        try {
            let response = await axios.put('rss/api/v2/subscriptions/content/watching', {
                rssUrl: rssUrl,
                url: url,
                title: title,
                guid: guid,
                progressSeconds: progress,
                totalSeconds: totalDuration,
            });
            if (response.status == 204) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    }
}