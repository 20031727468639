<template>
    <div>
        <div class="col-12" v-show="content.title">
            {{ content.title }}
        </div>

        <div class="col-12">
            <button
                v-show="enablePreviousButton"
                type="button"
                class="btn btn-lg btn-secondary mr-1"
                @click="previousButtonPressed"
            >
                <span class="fas fa-step-backward"></span>
            </button>

            <button
                v-show="enableAutoplayButton"
                class="btn btn-lg mr-1"
                :class="{
                    'btn-outline-success': !autoplay,
                    'btn-success': autoplay,
                }"
                @click="handleAutoplayButtonPressed"
            >
                <span class="far fa-play-circle"></span>
            </button>

            <button
                type="button"
                class="btn btn-lg btn-secondary mr-1 zoomfix"
                @click="skipBack"
            >
                <span class="fas fa-undo-alt fa-hfix"></span>
            </button>

            <button
                type="button"
                class="btn btn-lg btn-outline-success mr-1 zoomfix"
                @click="playPauseToggle"
            >
                <span class="fas fa-play" v-show="!isPlaying"></span>
                <span class="fas fa-pause" v-show="isPlaying"></span>
            </button>

            <button
                type="button"
                class="btn btn-lg btn-secondary mr-1 zoomfix"
                @click="skipForward"
            >
                <span class="fas fa-redo-alt fa-hfix"></span>
            </button>

            <button
                v-show="enableNextButton"
                type="button"
                class="btn btn-lg btn-secondary mr-1"
                @click="nextButtonPressed"
            >
                <span class="fas fa-step-forward"></span>
            </button>
        </div>
        <div class="col-12">
            <audio
                preload="none"
                style="width: 100%"
                @ended="handleContentCompleted"
                ref="audio"
                :autoplay="false"
                @emptied="handleStop"
                @pause="handleStop"
                @play="handlePlay"
                @durationchange="handleDurationChanged"
                @playing="handlePlay"
                @timeupdate="handleTimeUpdate"
            >
                <source :src="url" />
            </audio>
            <div>{{ progress }} / {{ duration }}</div>
        </div>
    </div>
</template>

<script>
import { ContentModel } from "../scripts/SubscriptionStore";

function formatTime(seconds) {
    if (Number.isNaN(seconds)) return "loading";
    let totalSeconds = seconds ?? 0;
    let h = Math.floor(totalSeconds / 3600);
    let m = Math.floor(totalSeconds / 60) % 60;
    let s = Math.floor(totalSeconds) % 60;
    return `${h.toString().padStart(2, "0")}:${m
        .toString()
        .padStart(2, "0")}:${s.toString().padStart(2, "0")}`;
}

export default {
    props: {
        content: ContentModel,
        enableAutoplayButton: Boolean,
        enableNextButton: Boolean,
        enablePreviousButton: Boolean,
    },
    computed: {
        url() {
            return this.content?.downloadUrl || this.content?.url;
        },
        progress() {
            return formatTime(this.content?.progress);
        },
        duration() {
            return formatTime(this.content?.duration);
        },
    },
    watch: {
        url() {
            this.reloadFromContent();
            if(this.autoplay) {
                this.$refs.audio.play();
            }
        },
    },
    mounted() {
        this.reloadFromContent();
    },
    data: () => ({
        isPlaying: false,
        lastLoadedUrl: "",
        autoplay: false,
    }),
    methods: {
        async reloadFromContent() {
            await this.$refs.audio.load();
            if (this.isPlaying) {
                this.$refs.audio.play();
            }
        },
        handleAutoplayButtonPressed() {
            this.autoplay = !this.autoplay;
        },
        playPauseToggle() {
            if (this.isPlaying) {
                this.$refs.audio.pause();
            } else {
                this.$refs.audio.play();
            }
        },
        skipBack() {
            this.$refs.audio.currentTime -= 10;
            this.$emit(
                "progress-changed",
                this.$refs.audio.currentTime,
                this.$refs.audio.duration
            );
        },
        skipForward() {
            this.$refs.audio.currentTime += 30;
            this.$emit(
                "progress-changed",
                this.$refs.audio.currentTime,
                this.$refs.audio.duration
            );
        },
        nextButtonPressed() {
            this.$emit("skip-next");
        },
        previousButtonPressed() {
            this.$emit("skip-previous");
        },
        handleContentCompleted() {
            this.isPlaying = false;
            this.$emit("completed");
            if (this.autoplay) this.$emit("autoplay-next");
        },
        handleStop() {
            this.isPlaying = false;
        },
        handlePlay() {
            this.isPlaying = true;
        },
        handleDurationChanged() {
            if (!this.$refs.audio) return;

            this.$refs.audio.currentTime = this.content.progress;

            this.$emit(
                "progress-changed",
                this.content.progress,
                this.$refs.audio.duration
            );
        },
        handleTimeUpdate() {
            if (!this.$refs.audio) return;

            this.$emit(
                "progress-changed",
                this.$refs.audio.currentTime,
                this.$refs.audio.duration
            );
        },
    },
};
</script>

<style>
</style>