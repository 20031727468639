import UserApi from './UserApi.js'

const STORAGE_KEY = "stored-user-v2";
const userApi = new UserApi();

export class UserInfo {
    constructor(username) {
        this.username = username;
    }
}
//TODO: refactor this to get user claims from cookie
export default class UserService {
    constructor() { }

    /**
     * @returns {UserInfo}
     */
    getLoggedInUser() {
        let json = localStorage.getItem(STORAGE_KEY);


        var cookie = document.cookie.split('; ').find(c => c.startsWith('rss-auth'));
        if (!cookie) {
            this.clearUser();
            return null;
        }

        if (!json)
            return null;
        let userRaw = JSON.parse(json);
        return new UserInfo(userRaw.username);
    }

    /**
     * 
     * @param {String} username 
     */
    setLoggedInUser(username) {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(new UserInfo(username)));
    }

    /**
     * 
     */
    clearUser() {
        localStorage.removeItem(STORAGE_KEY);
    }

    async validateLogin() {
        let user = this.getLoggedInUser();
        let apiUser = await userApi.getUser();
        return !!apiUser;
    }

    async login(username, secret) {
        this.clearUser();
        let success = await userApi.login(username, secret);
        if (success) {
            this.setLoggedInUser(username);
        }
        return success;
    }
}