import SubscriptionApi, { ContentListDto, SubscriptionListDto } from './SubscriptionApi';
import DownloadsRepository, { DownloadListItem } from './DownloadsRepository';
import UserService from './UserService';

const subscriptionApi = new SubscriptionApi();
const downloadsRepository = new DownloadsRepository();
const userService = new UserService();

/**
 * this is the subscription store
 * it works by keeping a massive list of loaded content behind the scenes
 * this content is kept up to date and sliced into subscriptions when loaded
 * subscriptions are like a view into the main list and populated via api & downloads
 */
export default class SubscriptionStore {
    constructor() {
        /** @type {SubscriptionModel[]} */
        this.subscriptions = [];
        this.downloadSubscription = new SubscriptionModel('DOWNLOAD', 'Downloads', '', true, true, 0, 0);
        this.mainFeedSubscription = new SubscriptionModel('MAIN_FEED', 'Feed', '', true, true, 0, 0);
        /** @type {ContentModel[]} */
        this.allContent = [];
        /** @type {ContentModel[]} */
        this.serverMainFeedContent = []; //used internally to resync main feed

        /** @type {ContentModel} */
        this.currentlyPlayingContent = null;

        this.hasLoadedSubscriptions = false;
        this.loading = false;

        //some player stuff
        this._lastLocalRefresh = new Date();
        this._lastDownloadRefresh = new Date();
        this._lastApiRefresh = new Date();
        /** @type {ContentModel} */
        this.playingContent = null;
    }

    async populateSubscriptions() {
        if (!userService.getLoggedInUser() || this.hasLoadedSubscriptions || this.loading)
            return;
        this.loading = true;

        //get downloads from repo
        let downloads = await downloadsRepository.getDownloadedList();
        //get main feed from api
        let mainFeedDtos = await subscriptionApi.getMainFeedContent();
        //get subscriptions metas for username from api
        let subscriptionDtos = await subscriptionApi.getSubscriptions();
        let subModels = subscriptionDtos.map(s => SubscriptionModel.fromApi(s));
        this.subscriptions.push(...subModels);

        let downloadContent = downloads.map(d => ContentModel.fromDownload(d, this.subscriptions.find(s => s.rssUrl == d.rssUrl)));
        let mainFeedContent = mainFeedDtos.map(c => ContentModel.fromApi(c, this.subscriptions.find(s => s.rssUrl == c.rssUrl)));

        //wire up content lists and push into main list
        downloadContent.forEach(dl => {
            let foundInFeedContentIndex = mainFeedContent.findIndex(fc => fc.isSameAsContent(dl));
            if (foundInFeedContentIndex >= 0) {
                mainFeedContent.splice(foundInFeedContentIndex, 1, dl);
            } else {
                this.allContent.push(dl);
            }
        });
        this.serverMainFeedContent.push(...mainFeedContent);
        this.allContent.push(...mainFeedContent);

        //push content into download and main feeds


        this.downloadSubscription.content.push(...downloadContent);
        this.downloadSubscription.content.sort((a, b) => b.date - a.date);
        this.downloadSubscription.loaded = true;
        this.downloadSubscription.resyncCounts();

        this.mainFeedSubscription.content.push(...mainFeedContent);
        this.mainFeedSubscription.content.sort((a, b) => b.date - a.date);
        this.mainFeedSubscription.loaded = true;
        this.mainFeedSubscription.resyncCounts();

        //get current playing info
        let playingContentDto = await subscriptionApi.getWatchingContent();
        if (playingContentDto) {
            let playingModel = this.allContent.find(c => c.isSameAs(playingContentDto.rssUrl, playingContentDto.title, playingContentDto.url, playingContentDto.guid));
            if (playingModel) {
                if (this.mainFeedSubscription.content.find(c => c.isSameAsContent(playingModel))) {
                    playingModel = await this.getContentForPlayerAndMarkAsPlaying(this.mainFeedSubscription.rssUrl, playingContentDto.title, playingContentDto.url, playingContentDto.guid);
                } else if(playingModel.downloaded) {
                    playingModel = await this.getContentForPlayerAndMarkAsPlaying(this.downloadSubscription.rssUrl, playingContentDto.title, playingContentDto.url, playingContentDto.guid);
                }
            } else {
                await this.getContentForPlayerAndMarkAsPlaying(playingContentDto.rssUrl, playingContentDto.title, playingContentDto.url, playingContentDto.guid);
            }
        }

        //call it all good
        this.hasLoadedSubscriptions = true;
        this.loading = false;
    }

    getSubscriptions() {
        let notified = this.subscriptions.filter(s => s.showNotifications);
        let unNotified = this.subscriptions.filter(s => !s.showNotifications);
        notified.sort((a, b) => a.title.localeCompare(b.title));
        unNotified.sort((a, b) => a.title.localeCompare(b.title));
        return [this.mainFeedSubscription, this.downloadSubscription, ...notified, ...unNotified];
    }

    async getSubscriptionContentList(rssUrl) {
        let sub = this.getSubscriptions().find(s => s.rssUrl == rssUrl);
        if (!sub) {
            return [];
        }

        if (!sub.loaded) {
            await this.populateSubscription(sub);
        }

        return sub.content;
    }

    async populateSubscription(subscription) {
        if (subscription.loaded && !subscription.loading)
            return;

        subscription.loading = true;
        let contentDtos = await subscriptionApi.getContent(subscription.rssUrl);
        if (!contentDtos)
            return [];

        //here we merge the api content with the already local content from other sources
        //there's definitely a better way than this n^2 operation to do this
        let contentModels = contentDtos.map(dto => {
            let foundLocal = this.allContent.find(c => c.isSameAs(dto.rssUrl, dto.title, dto.url, dto.guid));
            if (foundLocal) {
                //only update these values if it's not in the player, they're more up to date if it's in the player
                if (!foundLocal.watching) {
                    foundLocal.progress = dto.progress;
                    foundLocal.duration = dto.totalDuration;
                }
                foundLocal.watched = dto.watched;

                if(foundLocal.downloaded) {
                    if(foundLocal.progress < dto.progress)
                        foundLocal.progress = dto.progress;
                    
                    downloadsRepository.updateWatched(foundLocal.title, foundLocal.guid, foundLocal.url, foundLocal.rssUrl, foundLocal.watched, foundLocal.progress);
                }
                
                return foundLocal;
            }

            let newContent = ContentModel.fromApi(dto, subscription);
            this.allContent.push(newContent);
            return newContent;
        });

        subscription.content.push(...contentModels);

        subscription.loaded = true;
        subscription.resyncCounts();

        this.resyncMainFeed();

        subscription.loading = false;
    }

    resyncMainFeed() {
        /** @type {ContentModel[]} */
        let reinsertPlayingContent = false;
        if(this.mainFeedSubscription.watching && this.mainFeedSubscription.content.includes(this.playingContent)) {
            reinsertPlayingContent = true;
        }
        
        let feedContent = [];
        this.subscriptions.filter(s => s.includeInFeed).forEach(s => {
            if (s.loaded) {
                feedContent.push(...s.content.slice(0, s.unwatchedCount));
            } else {
                feedContent.push(...this.serverMainFeedContent.filter(c => c.rssUrl == s.rssUrl));
            }
        });
        //gets around a somewhat nasty bug where if the sub is loaded, it clears the playing content from the feed killing autoplay functionality
        if(reinsertPlayingContent && !feedContent.includes(this.playingContent)) {
            feedContent.push(this.playingContent);
        }

        //clear without killing binding ref
        this.mainFeedSubscription.content.splice(0, this.mainFeedSubscription.content.length);
        this.mainFeedSubscription.content.push(...feedContent);
        this.mainFeedSubscription.content.sort((a, b) => b.date - a.date);
        this.mainFeedSubscription.resyncCounts();
    }

    async markContentWatched(rssUrl, title, url, guid) {
        let sub = this.getSubscriptions().find(s => s.rssUrl == rssUrl);
        let content = this.allContent.find(c => c.isSameAs(sub.rssUrl, title, url, guid));
        if (content.watched)
            return;

        content.watched = true;
        sub.resyncCounts();
        //we just watched something, if it's from main feed we can approximate the unwatched content by recalculating from the server main feed content, if it's from the downloads tab and not in main feeds, we don't really know if it affects the unwatched count...?
        if (!content.subscription.loaded && content.subscription.includeInFeed) {
            this.resyncUnloadedCounts(content);
        }
        this.downloadSubscription.resyncCounts();
        this.mainFeedSubscription.resyncCounts();
        if (sub.includeInFeed) {
            this.resyncMainFeed();
        }
        await subscriptionApi.markContentWatched(content.rssUrl, content.title, content.guid, content.url);
        if (content.downloaded) {
            await downloadsRepository.updateWatched(content.title, content.guid, content.url, content.rssUrl, content.watched, content.progress, content.duration);
        }
    }
    async markContentUnWatched(rssUrl, title, url, guid) {
        let sub = this.getSubscriptions().find(s => s.rssUrl == rssUrl);
        let content = this.allContent.find(c => c.isSameAs(sub.rssUrl, title, url, guid));
        if (!content.watched)
            return;

        content.watched = false;
        sub.resyncCounts();
        //we just watched something, if it's from main feed we can approximate the unwatched content by recalculating from the server main feed content, if it's from the downloads tab and not in main feeds, we don't really know if it affects the unwatched count...?
        if (!content.subscription.loaded && content.subscription.includeInFeed) {
            this.resyncUnloadedCounts(content);
        }

        this.downloadSubscription.resyncCounts();
        this.mainFeedSubscription.resyncCounts();
        if (sub.includeInFeed) {
            this.resyncMainFeed();
        }
        await subscriptionApi.markContentUnWatched(content.rssUrl, content.title, content.guid, content.url);
        if (content.downloaded) {
            await downloadsRepository.updateWatched(content.title, content.guid, content.url, content.rssUrl, content.watched, content.progress, content.duration);
        }
    }
    resyncUnloadedCounts(content) {
        let mainFeedSubContent = this.mainFeedSubscription.content.filter(c => c.rssUrl == content.subscription.rssUrl);
        let unwatchedCount = mainFeedSubContent.findIndex(c => c.watched);
        if (unwatchedCount == -1)
            unwatchedCount = mainFeedSubContent.length;
        content.subscription.unwatchedCount = unwatchedCount;
    }

    /**
     * expect to be spammed
     * if it's different than the model playing, they forgot to call getContentForPlayerAndMarkAsPlaying
     * every second update the in memory object
     * every 10 seconds update the downloaded object if downloaded
     * every 30 seconds update via api
     * async void: handlePlayerProgressMade(contentIdentifiers...)
     * @param {String} rssUrl 
     * @param {String} title 
     * @param {String} url 
     * @param {String} guid 
     * @param {Number} progress 
     * @param {Number} duration 
     */
    handlePlayerProgressMade(rssUrl, title, url, guid, progress, duration) {
        if (Number.isNaN(progress) || Number.isNaN(duration) || progress <= 0 || duration <= 0) {
            return;
        }

        let now = new Date();

        if (this.playingContent.isSameAs(null, title, url, guid)) {
            this.playingContent.duration = duration;
            this.playingContent.progress = progress;
        }

        if (now - this._lastLocalRefresh > 1000) {
            this._lastLocalRefresh = now;
            //ensure it's playing current object, load if not
            if (!this.playingContent || !this.playingContent.isSameAs(rssUrl, title, url, guid)) {
                this.getContentForPlayerAndMarkAsPlaying(this.playingContent.rssUrl, this.playingContent.title, this.playingContent.url, this.playingContent.guid);
            }
            //update progress
            this.playingContent.progress = progress;
            this.playingContent.duration = duration;

            if (this.playingContent.downloaded && now - this._lastDownloadRefresh > 3000) {
                this._lastDownloadRefresh = now;
                //update download progress
                downloadsRepository.updateWatched(this.playingContent.title, this.playingContent.guid, this.playingContent.url, this.playingContent.rssUrl, this.playingContent.watched, this.playingContent.progress, this.playingContent.duration);
            }
            if (now - this._lastApiRefresh > 30000) {
                this._lastApiRefresh = now;
                //update api
                subscriptionApi.setWatchingContent(this.playingContent.rssUrl, this.playingContent.title, this.playingContent.url, this.playingContent.guid, Math.round(progress), Math.round(duration));
            }
        }
    }

    /**
     * marks all watching bits, sets current playing object correctly, sets up downloaded content urls
     * @param {String} rssUrl 
     * @param {String} title 
     * @param {String} url 
     * @param {String} guid 
     * @returns {ContentModel}
     */
    async getContentForPlayerAndMarkAsPlaying(rssUrl, title, url, guid) {
        //early return if it's already the one
        if (this.playingContent && this.playingContent.isSameAs(null, title, url, guid))
            return this.playingContent;

        //mark content and sub watching and previous not watching
        let sub = this.getSubscriptions().find(s => s.rssUrl == rssUrl);
        if (!sub)
            return null;
        if (!sub.loaded) {
            await this.populateSubscription(sub);
        }
        let content = sub.content.find(c => c.isSameAs(null, title, url, guid));
        if (!content)
            return null;

        if (this.playingContent) {
            this.playingContent.watching = false;
        }
        this.getSubscriptions().forEach(s => s.watching = (s.rssUrl == rssUrl));
        content.watching = true;

        if (content.downloaded && !content.downloadUrl) {
            let dl = await downloadsRepository.getDownloadData(content.title, content.guid, content.url, content.rssUrl)
            if (dl) {
                content.downloadUrl = URL.createObjectURL(dl.data);
            } else {
                content.downloaded = false;
            }
        }

        this.playingContent = content;
        return content;
    }

    /**
     * downloads content if found, returns downloaded data blob?
     * @param {String} rssUrl 
     * @param {String} title 
     * @param {String} url 
     * @param {String} guid 
     */
    async downloadContent(rssUrl, title, url, guid) {
        let sub = this.getSubscriptions().find(s => s.rssUrl == rssUrl);
        let content = this.allContent.find(c => c.isSameAs(sub.rssUrl, title, url, guid));
        if (!content || content.downloaded)
            return;

        content.downloading = true;
        let dlData = await downloadsRepository.download(content.title, content.guid, content.url, content.date, content.description, content.watched, content.rssUrl, content.progress, content.duration);
        content.downloading = false;
        content.downloaded = dlData != null;
        if (dlData?.data) {
            content.downloadUrl = URL.createObjectURL(dlData.data);
        }

        this.downloadSubscription.content.push(content);
        this.downloadSubscription.content.sort((a, b) => b.date - a.date);
        this.downloadSubscription.resyncCounts();
    }

    /**
     * @param {String} rssUrl 
     * @param {String} title 
     * @param {String} url 
     * @param {String} guid 
     */
    async deleteDownloadedContent(rssUrl, title, url, guid) {
        let contentIndex = this.downloadSubscription.content.findIndex(d => d.isSameAs(rssUrl, title, url, guid));
        if (contentIndex >= 0) {
            this.downloadSubscription.content.splice(contentIndex, 1)[0];
            this.downloadSubscription.resyncCounts();
        }
        let foundContent = this.allContent.find(c => c.isSameAs(rssUrl, title, url, guid));
        if (foundContent) {
            foundContent.downloaded = false;
            foundContent.downloadUrl = null;

            if (foundContent.watching) {
                let currentSub = this.getSubscriptions().find(s => s.watching);
                if (currentSub) currentSub.watching = false;

                let foundSub = this.getSubscriptions().find(s => s.rssUrl == foundContent.rssUrl);
                foundSub.watching = true;

            }
        }
        await downloadsRepository.delete(title, guid, url, rssUrl);
    }

    /**
     * @returns {ContentModel}
     */
    getNextContent() {
        if (!this.playingContent)
            return null;

        let sub = this.getSubscriptions().find(s => s.watching) ?? this.getSubscriptions().find(s => this.playingContent.rssUrl == s.rssUrl);
        let contentIndex = sub?.content.findIndex(c => c.isSameAs(null, this.playingContenttitle, this.playingContenturl, this.playingContent.guid));
        if (contentIndex > 0)
            return sub.content[contentIndex - 1];
        return null;
    }

    getPreviousContent() {
        if (!this.playingContent)
            return null;

        let sub = this.getSubscriptions().find(s => s.watching) ?? this.getSubscriptions().find(s => this.playingContent.rssUrl == s.rssUrl);
        let contentIndex = sub?.content.findIndex(c => c.isSameAs(null, this.playingContenttitle, this.playingContenturl, this.playingContent.guid));
        if (contentIndex < sub.content.length)
            return sub.content[contentIndex + 1];
        return null;
    }

    async getFirstUnwatchedContent(rssUrl) {
        let sub = this.getSubscriptions().find(s => s.rssUrl == rssUrl);
        if (!sub || sub.unwatchedCount == 0)
            return null;
        await this.populateSubscription(sub);
        if (sub.unwatchedCount == 0)
            return null;
        return sub.content[sub.unwatchedCount - 1];
    }

    async getLastUnwatchedContent(rssUrl) {
        let sub = this.getSubscriptions().find(s => s.rssUrl == rssUrl);
        if (!sub || sub.allUnwatchedCount == 0)
            return null;
        await this.populateSubscription(sub);
        if (sub.allUnwatchedCount == 0)
            return null;
        let lastUnwatched = sub.content.filter(c => !c.watched).reverse()[0];
        return lastUnwatched;
    }

    async updateSubscriptionPreferences(rssUrl, includeInFeed, showNotifications) {
        let sub = this.subscriptions.find(s => s.rssUrl == rssUrl);
        if (!sub)
            return;
        sub.includeInFeed = Boolean(includeInFeed);
        sub.showNotifications = Boolean(showNotifications);

        this.resyncMainFeed();

        //if we're playing content from the main feed and we just knocked that out of the main feed by changing preferences, swap watching to the sub that it's sourced from
        if (this.playingContent && this.mainFeedSubscription.watching && !this.mainFeedSubscription.content.find(c => c.isSameAsContent(this.playingContent))) {
            this.mainFeedSubscription.watching = false;
            this.playingContent.subscription.watching = true;
        }

        await subscriptionApi.updatePreferences(sub.rssUrl, sub.includeInFeed, sub.showNotifications);
    }

    getPlayingSubscription() {
        let sub = this.getSubscriptions().find(s => s.watching);
        return sub;
    }
}

export class SubscriptionModel {
    /**
     * 
     * @param {String} rssUrl 
     * @param {String} title 
     * @param {String} description 
     */
    constructor(rssUrl, title, description, includeInFeed, showNotifications, unwatchedCount, allUnwatchedCount) {
        this.rssUrl = String(rssUrl);
        this.title = String(title);
        this.description = String(description);
        this.includeInFeed = Boolean(includeInFeed);
        this.showNotifications = Boolean(showNotifications);
        this.unwatchedCount = Number(unwatchedCount);
        this.allUnwatchedCount = Number(allUnwatchedCount);

        /** @type {ContentModel[]} */
        this.content = [];
        this.watching = false
        this.loading = false;
        this.loaded = false;
    }

    resyncCounts() {
        if (!this.loaded) //can't do shit if we don't have content
            return;

        //first is unwatchedCount
        let firstWatchedIndex = this.content.findIndex(c => c.watched);
        if (firstWatchedIndex >= 0)
            this.unwatchedCount = firstWatchedIndex;
        else
            this.unwatchedCount = this.content.length;

        //all
        this.allUnwatchedCount = this.content.filter(c => !c.watched).length;
    }

    /**
     * 
     * @param {SubscriptionListDto} dto 
     * @returns {SubscriptionModel}
     */
    static fromApi(dto) {
        return new SubscriptionModel(dto.url, dto.title, dto.description, dto.preferences.includeInFeed, dto.preferences.showNotifications, dto.unwatchedCount, dto.allUnwatchedCount);
    }
}


export class ContentModel {
    /**
     * @param {String} rssUrl 
     * @param {String} title 
     * @param {String} url 
     * @param {String} guid 
     * @param {Date} date 
     * @param {String} description 
     * @param {Boolean} watched 
     * @param {Boolean} downloaded 
     * @param {Number} progress 
     * @param {Number} duration 
     * @param {SubscriptionModel} subscription
     */
    constructor(rssUrl, title, url, guid, date, description, watched, downloaded, progress, duration, subscription) {
        //from rss
        this.rssUrl = String(rssUrl);
        this.title = String(title);
        this.url = String(url);
        this.guid = String(guid);
        this.date = new Date(date);
        this.description = String(description);

        //from api
        this.watched = Boolean(watched);
        this.downloaded = Boolean(downloaded);
        this.progress = Number(progress);
        this.duration = Number(duration);

        //display bugfix/hack
        if(Number.isNaN(this.duration) && !Number.isNaN(this.progress))
            this.duration = this.progress * 2;

        //ui/state management
        this.subscription = subscription;
        this.watching = false;
        this.downloading = false;
        /** @type {String} */
        this.downloadUrl = null;
    }

    /**
     * 
     * @param {ContentListDto} dto 
     * @param {SubscriptionModel} subscription
     * @returns {ContentModel}
     */
    static fromApi(dto, subscription) {
        return new ContentModel(dto.rssUrl, dto.title, dto.url, dto.guid, dto.date, dto.description, dto.watched, dto.downloaded, dto.progress, dto.totalDuration, subscription);
    }

    /**
     * 
     * @param {DownloadListItem} dl 
     * @param {SubscriptionModel} subscription
     * @returns {ContentModel}
     */
    static fromDownload(dl, subscription) {
        return new ContentModel(dl.rssUrl, dl.title, dl.contentUrl, dl.guid, dl.date, dl.description, dl.watched, true, dl.progress, dl.totalDuration, subscription);
    }

    /**
     * @param {ContentModel} content 
     * @returns {Boolean}
     */
    isSameAsContent(content) {
        return this.isSameAs(content.rssUrl, content.title, content.url, content.guid);
    }

    /**
     * checks for a match of rssUrl if provided, and one of url, guid, and title. url also matches against downloadurl
     * @param {String} rssUrl 
     * @param {String} title 
     * @param {String} url 
     * @param {String} guid 
     * @returns 
     */
    isSameAs(rssUrl, title, url, guid) {
        return (!rssUrl || this.rssUrl == rssUrl) && (this.url == url || (this.downloadUrl && this.downloadUrl == url) || (this.guid && guid && this.guid == guid) || this.title == title);
    }
}
