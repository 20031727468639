<template>
    <div>
        <div>
            Sleep Time Player
        </div>
        <div v-if="errorText" class="alert alert-danger">
            {{ errorText }}
        </div>
        <div>
            <ContentPlayerMini 
                v-show="true" 
                :content="playingContent" 
                :progress="playerProgress" 
                :autoplay="false"
                :enableNextButton="true" 
                :enablePreviousButton="true"

                @player-save="handlePlayerSave" 
                @player-completed="handlePlayerCompleted"
                @player-next="handlePlayerNext"
                @player-previous="handlePlayerPrevious"/>
        </div>
    </div>
</template>
<script>
import SubscriptionApi from "../scripts/SubscriptionApi";
import UserService from "../scripts/UserService";
import ContentPlayerMini, { ContentPlayerInputModel } from "../components/ContentPlayerMini.vue";

const subApi = new SubscriptionApi();

export default {
    components: { ContentPlayerMini },
    async mounted() {
        let usr = new UserService();
        let user = usr.getLoggedInUser();
        if (!user)
            return;

        await this.loadCurrentIndex();
    },
    data: () => ({
        playingContent: null,
        playerProgress: 0,
        errorText: "",
        sub: null,
    }),
    methods: {
        //I don't think we want this to do anything 
        handlePlayerSave() { },

        async handlePlayerCompleted() {
            await this.progressIndexForward();
        },

        async handlePlayerNext() {
            await this.progressIndexForward();
            await this.loadCurrentIndex();
        },

        async handlePlayerPrevious() {
            let cIndex = this.content.findIndex(c => c.guid == this.sub.index.guid || c.title == this.sub.index.title || c.url == this.sub.index.url);
            let previous = this.content[cIndex + 1];
            await subApi.updateIndex(this.sub.url, previous.title, previous.guid, previous.url);
            await this.loadCurrentIndex();
        },

        async loadCurrentIndex() {
            let subs = await subApi.getSubscriptions();
            let hftmt = subs.find(s => s.title == "Hello From The Magic Tavern");
            this.sub = hftmt;
            if (hftmt.index?.title) {
                this.content = await subApi.getContent(hftmt.url);
                let fullContentIndex = this.content.find(c => c.url == hftmt.index.url || c.guid == hftmt.index.guid || c.title == hftmt.index.title);
                this.playingContent = new ContentPlayerInputModel(fullContentIndex.title, fullContentIndex.guid, fullContentIndex.url, null, hftmt.url, true);
            } else {
                this.errorText = "No Index Found";
            }
        },

        async progressIndexForward() {
            let cIndex = this.content.findIndex(c => c.guid == this.sub.index.guid || c.title == this.sub.index.title || c.url == this.sub.index.url);
            let next = this.content[cIndex - 1];
            await subApi.updateIndex(this.sub.url, next.title, next.guid, next.url);
        }
    }
}
</script>