<template>
    <div>
        <ul class="list-group">

            <li class="content-list-item list-group-item one-line text-left progress-container" v-for="content in orderedContent" :key="content.guid"
                :class="{ 'list-group-item-secondary': content.watched, 'list-group-item-success': content.watching }">
                <div class="float-left">
                    <button :disabled="content.downloading" v-show="!content.downloaded" @click="downloadButtonPressed(content)" class="btn btn-sm btn-success">
                        <span class="fas fa-save fa-hfix"></span>
                    </button>
                    <button :disabled="content.downloading" v-show="content.downloaded" @click="deleteButtonPressed(content)" class="btn btn-sm btn-danger">
                        <span class="fas fa-trash-alt fa-hfix"></span>
                    </button>
                </div>

                <div class="float-right">
                    <button @click="playButtonPressed(content)" class="btn btn-sm btn-success">
                        <span class="fas fa-play"></span>
                    </button>
                    <button :disabled="content.downloading" @click="watchedButtonPressed(content)" class="btn btn-sm btn-outline-secondary">
                        <span class="fas fa-check fa-hfix" v-show="!content.watched"></span>
                        <span class="fas fa-times fa-hfix" v-show="content.watched"></span>
                    </button>
                </div>

                {{ content.title }}

                <br />

                <span class="text-muted">{{ content.date }}</span>
                
                <div class="progress bg-success" 
                    v-show="!content.watched && content.progress" 
                    :style="{width: (content.progress / content.totalDuration * 100) + '%' }"></div>

            </li>

        </ul>
    </div>
</template>

<script>
export class ContentItemViewmodel {
    /**
     * 
     * @param {String} title 
     * @param {Date} date 
     * @param {String} description 
     * @param {String} guid 
     * @param {String} url 
     * @param {String} rssUrl
     * @param {Boolean} watched 
     * @param {Boolean} downloaded
     * @param {Number} progress
     * @param {Number} totalDuration
     */
    constructor(title, date, description, guid, url, rssUrl, watched, downloaded, progress, totalDuration) {
        this.title = String(title);
        this.date = new Date(date).toLocaleDateString();
        this.description = String(description);
        this.guid = String(guid);
        this.url = String(url);
        this.rssUrl = String(rssUrl);
        this.watched = Boolean(watched);
        this.downloaded = Boolean(downloaded);
        this.progress = Number(progress);
        this.totalDuration = Number(totalDuration);

        /** @type {Boolean} */
        this.watching = false;
        /** @type {Boolean} */
        this.downloading = false;
    }
}

export default {
    props: {
        content: Array
    },
    computed: {
        orderedContent() {
            /** @type {ContentItemViewmodel[]} */
            return [...this.content];
        }
    },
    mounted() {
        let playing = this.$el.querySelector('.list-group-item-success');
        if(playing) {
            playing.scrollIntoView({behavior: 'smooth'});
        } else {
            document.documentElement.scrollTo(0,0);
        }
    },
    methods: {
        /** @param {ContentItemViewmodel} content */
        async downloadButtonPressed(content) {
            this.$emit('download', content);
        },
        /** @param {ContentItemViewmodel} content */
        deleteButtonPressed(content) {
            this.$emit('download-delete', content);
        },
        /** @param {ContentItemViewmodel} content */
        playButtonPressed(content) {
            this.$emit('play-content', content);
        },
        /** @param {ContentItemViewmodel} content */
        watchedButtonPressed(content) {
            this.$emit('content-watched', content);
        },
        /** @param {Number} seconds */
        formatTimeSeconds(seconds) {
            let totalSeconds = seconds ?? 0;
            let h = Math.floor(totalSeconds / 3600);
            let m = Math.floor(totalSeconds / 60) % 60;
            let s = Math.floor(totalSeconds) % 60;
            return `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}:${s.toString().padStart(2, '0')}`;
        }
    }
}
</script>

<style>
.progress {
    position: absolute;
    bottom: 3px;
    left: 0;
    width: 100%;
    height: .8em;
}

.content-list-item {
}

.progress-container {
}
</style>
