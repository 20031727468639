<template>
    <div>
        <div v-if="!selectedSubscription" class="list-group">
            <button
                class="list-group-item list-group-item-action"
                v-for="subscription in subscriptionList"
                :key="subscription.url"
                @click="subscriptionListButtonPressed(subscription)"
                :class="{
                    'list-group-item-secondary':
                        !subscription.showNotifications,
                    'list-group-item-success': subscription.isPlaying,
                }"
                :disabled="subscription.loading"
            >
                <button
                    v-if="
                        subscription.unwatchedCount > 0 &&
                        subscription.showNotifications
                    "
                    class="btn btn-sm btn-success float-left"
                    @click.stop="handlePlayNextButtonPressed(subscription)"
                >
                    <span class="fas fa-play"></span>
                </button>

                <button
                    v-if="
                        subscription.allUnwatchedCount >
                            subscription.unwatchedCount &&
                        subscription.showNotifications
                    "
                    class="btn btn-sm btn-warning float-left"
                    @click.stop="handlePlayReverseButtonPressed(subscription)"
                >
                    <span class="fas fa-play"></span>
                </button>

                {{ subscription.title }}

                <span
                    v-show="
                        subscription.showNotifications &&
                        subscription.unwatchedCount
                    "
                    class="badge badge-pill badge-info float-right"
                >
                    {{ subscription.unwatchedCount }}
                </span>
            </button>
        </div>
        <div v-if="selectedSubscription">
            <button
                class="btn btn-outline-secondary float-left back-button"
                @click="subscriptionBackButtonPressed"
            >
                &lt;
            </button>
            <h1>
                {{ selectedSubscription.title }}
            </h1>
            <div
                v-if="selectedSubscription.url != 'DOWNLOADS'"
                :class="{ 'description-collapsed': !descriptionExpanded }"
                @click="descriptionExpanded = !descriptionExpanded"
            >
                <p v-html="selectedSubscription.description"></p>
                <div>
                    <button
                        @click.stop="handleIncludeInFeedButtonPressed"
                        class="btn btn-lg btn-outline-secondary"
                    >
                        <span
                            v-show="selectedSubscription.includeInFeed"
                            class="fa fa-eye"
                        ></span>
                        <span
                            v-show="!selectedSubscription.includeInFeed"
                            class="fa fa-eye-slash"
                        ></span>
                    </button>
                    <button
                        @click.stop="handleShowNotificationButtonPressed"
                        class="btn btn-lg btn-outline-secondary"
                    >
                        <span
                            v-show="selectedSubscription.showNotifications"
                            class="fa fa-bell"
                        ></span>
                        <span
                            v-show="!selectedSubscription.showNotifications"
                            class="fa fa-bell-slash"
                        ></span>
                    </button>
                </div>
            </div>
            <button
                v-show="descriptionExpanded"
                class="btn btn-sm btn-block btn-outline-secondary"
                @click="descriptionExpanded = false"
            >
                Collapse
            </button>
            <ContentMinListVue
                @content-watched="handleContentWatched"
                @play-content="handlePlayContentEvent"
                @download="$emit('download', $event)"
                @download-delete="$emit('download-delete', $event)"
                :content="selectedSubscription.content"
            ></ContentMinListVue>
        </div>
        <div class="spacer"></div>
    </div>
</template>

<script>
import ContentMinListVue, { ContentItemViewmodel } from "./ContentMinList.vue";
import SubscriptionApi from "../scripts/SubscriptionApi";
import DownloadsRepository from "../scripts/DownloadsRepository";

const subApi = new SubscriptionApi();
const dlRepo = new DownloadsRepository();

export default {
    components: { ContentMinListVue },
    props: {
        subscriptions: Array,
    },
    computed: {
        /** @type {SubscriptionMinListViewmodel[]} */
        subscriptionList() {
            /** @type {SubscriptionMinListViewmodel[]} */
            let list = [...this.subscriptions].sort((a, b) =>
                a.title > b.title ? 1 : -1
            );
            let stickied = []; //things like downloads and feeds
            let notifiedAndUnwatched = [];
            let notifiedAndWatched = [];
            let unNotified = [];

            list.forEach((s) => {
                if (s.url == "DOWNLOADS") {
                    this.downloadSubscription = s;
                    if (s.content.length > 0) stickied.push(s);
                } else if (s.url == "FEED") stickied.push(s);
                else if (s.showNotifications && s.unwatchedCount > 0)
                    notifiedAndUnwatched.push(s);
                else if (s.showNotifications && s.unwatchedCount <= 0)
                    notifiedAndWatched.push(s);
                else unNotified.push(s);
            });

            return [
                ...stickied,
                ...notifiedAndUnwatched,
                ...notifiedAndWatched,
                ...unNotified,
            ];
        },
    },
    data: () => ({
        selectedSubscription: null,
        descriptionExpanded: false,
        /** @type {SubscriptionMinListViewmodel} */
        downloadSubscription: null,
    }),
    mounted() {},
    methods: {
        /** @param {SubscriptionMinListViewmodel} sub */
        async subscriptionListButtonPressed(sub) {
            await sub.loadData();
            this.saveScrolling();
            this.selectedSubscription = sub;
        },
        subscriptionBackButtonPressed() {
            this.selectedSubscription = null;
            this.restoreScrolling();
        },
        handlePlayContentEvent(content) {
            this.$emit("play-content", content);
        },
        /** @param {ContentItemViewmodel} content */
        handleContentWatched(content) {
            if (content.watched)
                subApi.markContentUnWatched(
                    content.rssUrl,
                    content.title,
                    content.guid,
                    content.url
                );
            else
                subApi.markContentWatched(
                    content.rssUrl,
                    content.title,
                    content.guid,
                    content.url
                );

            content.watched = !content.watched;

            dlRepo.updateWatched(
                content.title,
                content.guid,
                content.url,
                content.rssUrl,
                content.watched,
                0,
                content.totalDuration
            );

            if (content.downloaded)
                this.downloadSubscription.recalculateUnwatched();

            /** @type {SubscriptionMinListViewmodel} */
            let sub = this.subscriptions.find((s) => s.url == content.rssUrl);
            if (sub) sub.recalculateUnwatched();
        },
        /** @param {SubscriptionMinListViewmodel} sub */
        async handlePlayNextButtonPressed(sub) {
            let next = await sub.getNextContentToPlay();
            if (next) this.$emit("play-content", next);
        },
        async handlePlayReverseButtonPressed(sub) {
            let next = await sub.getNetxtReverseContentToPlay();
            if (next) this.$emit("play-content", next);
        },
        async handleIncludeInFeedButtonPressed() {
            /** @type {SubscriptionMinListViewmodel} */
            let sub = this.selectedSubscription;
            sub.includeInFeed = !sub.includeInFeed;
            await subApi.updatePreferences(
                sub.url,
                sub.includeInFeed,
                sub.showNotifications
            );
        },
        async handleShowNotificationButtonPressed() {
            /** @type {SubscriptionMinListViewmodel} */
            let sub = this.selectedSubscription;
            sub.showNotifications = !sub.showNotifications;
            await subApi.updatePreferences(
                sub.url,
                sub.includeInFeed,
                sub.showNotifications
            );
        },
        saveScrolling() {
            this.savedScrolling = document.documentElement.scrollTop;
        },
        restoreScrolling() {
            if (this.savedScrolling || this.savedScrolling == 0) {
                document.documentElement.scrollTo(0, this.savedScrolling);
                this.savedScrolling = null;
            }
        },
    },
};

export class SubscriptionMinListViewmodel {
    /**
     *
     * @param {String} title
     * @param {String} url
     * @param {String} description
     * @param {Boolean} includeInFeed
     * @param {Boolean} showNotifications
     * @param {Number} unwatchedCount
     * @param {String} indexTitle
     * @param {String} indexGuid
     * @param {String} indexUrl
     * @param {Number} allUnwatchedCount
     * @param {ContentItemViewmodel[]} downloadedContent
     */
    constructor(
        title,
        url,
        description,
        includeInFeed,
        showNotifications,
        unwatchedCount,
        indexTitle,
        indexGuid,
        indexUrl,
        allUnwatchedCount,
        downloadedContent
    ) {
        this.title = String(title);
        this.url = String(url);
        this.description = String(description);
        this.includeInFeed = Boolean(includeInFeed);
        this.showNotifications = Boolean(showNotifications);
        this.unwatchedCount = Number(unwatchedCount);

        this.allUnwatchedCount = Number(allUnwatchedCount);

        this.indexUrl = String(indexUrl);
        this.indexGuid = String(indexGuid);
        this.indexTitle = String(indexTitle);

        this.hasLoadedBody = false;
        /** @type {ContentItemViewmodel[]} */
        this.content = [];
        this.isPlaying = false;
        this.loading = false;
        /** @type {ContentItemViewmodel[]} */
        this.downloadedContent = downloadedContent || [];
    }

    async loadData() {
        if (this.hasLoadedBody || this.loading) return;

        this.loading = true;
        let dtos = await subApi.getContent(this.url);
        if (dtos == null) {
            this.loading = false;
            return;
        }

        this.content = dtos.map((d) => {
            let foundDl = this.downloadedContent.find(
                (i) => i.title == d.title || i.guid == d.guid || i.url == d.url
            );
            if (foundDl) {
                foundDl.progress = d.progress;
                foundDl.totalDuration =
                    foundDl.totalDuration > 1
                        ? foundDl.totalDuration
                        : d.totalDuration; //this is an iffy one, the local downloaded version's length probably wouldnt change so this may not be true of the already downloaded content...?
                foundDl.watched = d.watched;
                dlRepo.updateWatched(
                    foundDl.title,
                    foundDl.guid,
                    foundDl.url,
                    foundDl.rssUrl,
                    foundDl.watched,
                    foundDl.progress,
                    foundDl.totalDuration
                );
                return foundDl;
            }
            return new ContentItemViewmodel(
                d.title,
                d.date,
                d.description,
                d.guid,
                d.url,
                this.url,
                d.watched,
                false,
                d.progress,
                d.totalDuration
            );
        });

        //recursion sorta happens but should be blocked by loading status, but this should fix some desync issues
        await this.recalculateUnwatched();

        this.loading = false;
        this.hasLoadedBody = true;
    }

    async getNextContentToPlay() {
        await this.loadData();
        let index = this.content.findIndex((c) => c.watched);
        if (index == -1) return this.content[this.content.length - 1];
        else if (index == 0) return null;
        else return this.content[index - 1];
    }

    async getNetxtReverseContentToPlay() {
        await this.loadData();
        let unwatched = this.content.filter((c) => !c.watched);
        if (!unwatched.length) return null;
        return unwatched.reverse()[0];
    }

    async recalculateUnwatched() {
        await this.loadData();
        //correctly order content
        this.content.sort((a, b) => {
            var aDate = Date.parse(a.date);
            var bDate = Date.parse(b.date);
            if (aDate > bDate) return -1;
            if (aDate < bDate) return 1;
            return 0;
        });

        let index = this.content.findIndex((c) => c.watched);
        if (index == -1) this.unwatchedCount = this.content.length;
        else this.unwatchedCount = index;

        this.allUnwatchedCount = this.content.filter((c) => !c.watched).length;
    }
}
</script>

<style>
.description-collapsed {
    max-height: 2em;
    overflow: hidden;
}

.spacer {
    min-height: 12em;
}

.back-button {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 6;
    background-color: white;
}
</style>
