import axios from "axios";

export class UserDto {
    /**
     * 
     * @param {String} username 
     */
    constructor(username) {
        this.username = username;
    }
}

export default class UserApi {
    /**
     * GET /rss/api/v2/users
     * @returns {UserDto}
     */
    async getUser() {
        try {
            let response = await axios.get('rss/api/v2/users');
            if (response.data) {
                return new UserDto(response.data.username);
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    }

    /**
     * POST /rss/api/v2/users
     * @returns {Boolean}
     */
    async createUser() {
        try {
            let response = await axios.post('rss/api/v2/users');
            if (response.status == 204) {
                return true
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    }

    /**
     * 
     * @param {String} username 
     * @returns {Boolean}
     */
    async login(username, secret) {
        try {
            let response = await axios.post('rss/api/v2/users/login', { username: username, secret: secret });
            if (response.status == 204)
                return true;
            else
                return false
        } catch (error) {
            return false;
        }
    }

    async logout() {
        try {
            let response = await axios.post('rss/api/v2/users/logout');
            if (response.status == 200)
                return true;
            else
                return false
        } catch (error) {
            return false;
        }
    }
}