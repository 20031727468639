import axios from "axios";

const DB_NAME = 'rss-download-db';
const DB_STORE_NAME = 'downloads';
const DB_VERSION = 1;
const SESSION_STORE_KEY = 'rss-download-progress';


/**
 * @returns {indexedDB}
 */
async function getDb() {
    return new Promise((yay, nay) => {
        if (db)
            yay(db);
        else {
            setTimeout(async () => {
                yay(await getDb())
            }, 200);
        }
    });
}

function awaitArray(onsuccsessor) {
    return new Promise((yay, nay) => {
        onsuccsessor.onsuccess = e => {
            yay(onsuccsessor.result || []);
        }
        onsuccsessor.onerror = e => {
            nay(null)
        }
    });
}

export default class DownloadsRepository {
    constructor() {
    }

    /**
     * @returns {DownloadListItem[]}
     */
    async getDownloadedList() {
        try {
            let database = await getDb();
            let transaction = database.transaction(DB_STORE_NAME, 'readonly');
            let store = transaction.objectStore(DB_STORE_NAME);
            let all = await awaitArray(store.getAll());
            let meta = (all || []).map(e => new DownloadListItem(e.title, e.guid, e.contentUrl, e.rssUrl, e.date, e.description, false, 0, 1));

            meta.forEach(c => {
                let found = sessionData.find(d => d.rssUrl == c.rssUrl && (d.contentUrl == c.contentUrl || d.title == c.title || d.guid == d.contentUrl));
                if (found) {
                    c.progress = found.progress;
                    c.totalDuration = found.totalDuration;
                    c.watched = found.watched;
                }
            });

            return meta;
        } catch (e) {
            console.log(e);
            return [];
        }
    }

    /**
     * @param {String} rssUrl 
     * @returns {DownloadListItem[]}
     */
    async getDownloadsForSubscription(rssUrl) {
        try {
            let database = await getDb();
            let transaction = database.transaction(DB_STORE_NAME, 'readonly');
            let store = transaction.objectStore(DB_STORE_NAME);
            let results = await awaitArray(store.index('rssUrl').getAll(rssUrl));
            let meta = results.map(r => new DownloadListItem(r.title, r.guid, r.contentUrl, r.rssUrl, r.date, r.description, false, 0, 1));

            meta.forEach(c => {
                let found = sessionData.find(d => d.rssUrl == c.rssUrl && (d.contentUrl == c.contentUrl || d.title == c.title || d.guid == d.contentUrl));
                if (found) {
                    c.progress = found.progress;
                    c.totalDuration = found.totalDuration;
                    c.watched = found.watched;
                }
            });

            return meta;
        } catch {
            return [];
        }
    }

    /**
     * 
     * @param {String} title 
     * @param {String} guid 
     * @param {String} contentUrl 
     * @param {String} rssUrl 
     * @returns {DownloadedContent}
     */
    async getDownloadData(title, guid, contentUrl, rssUrl) {
        try {
            let database = await getDb();
            let transaction = database.transaction(DB_STORE_NAME, 'readonly');
            let store = transaction.objectStore(DB_STORE_NAME);

            let found = (await awaitArray(store.getAll(contentUrl))).filter(i => i.rssUrl = rssUrl);
            if (!found.length)
                found = (await awaitArray(store.index('guid').getAll(guid))).filter(i => i.rssUrl = rssUrl);
            if (!found.length)
                found = (await awaitArray(store.index('title').getAll(title))).filter(i => i.rssUrl = rssUrl);

            let progress = sessionData.find(d => d.rssUrl == found[0].rssUrl && (d.contentUrl == found[0].contentUrl || d.title == found[0].title || d.guid == found[0].contentUrl))

            return new DownloadedContent(found[0].title, found[0].guid, found[0].contentUrl, found[0].rssUrl, found[0].date, found[0].description, progress?.watched || false, found[0].data, progress?.progress || 0, progress?.totalDuration || 1);
        } catch {
            return null;
        }
    }

    /**
     * 
     * @param {String} title 
     * @param {String} guid 
     * @param {String} contentUrl 
     * @param {String} rssUrl 
     * @param {Boolean} watched
     * @param {Number} progress
     * @param {Number} totalDuration
     */
    async updateWatched(title, guid, contentUrl, rssUrl, watched, progress, totalDuration) {
        let data = await this.getDownloadData(title, guid, contentUrl, rssUrl);
        if (!data)
            return;

        try {
            let foundIndex = sessionData.findIndex(d => d.rssUrl == rssUrl && (d.title == title || d.guid == guid || d.contentUrl == contentUrl));
            if (foundIndex >= 0) {
                sessionData.splice(foundIndex, 1);
            }
            sessionData.push(new DownloadProgressData(title, guid, contentUrl, rssUrl, watched, progress, totalDuration));
            localStorage.setItem(SESSION_STORE_KEY, JSON.stringify(sessionData));
        } catch {
        }
    }

    /**
     * 
     * @param {String} title 
     * @param {String} guid 
     * @param {String} contentUrl 
     * @returns {Boolean} success
     */
    async delete(title, guid, contentUrl, rssUrl) {
        let found = await this.getDownloadData(title, guid, contentUrl, rssUrl);
        if (!found)
            return false;

        try {
            let transaction = db.transaction(DB_STORE_NAME, 'readwrite');
            let store = transaction.objectStore(DB_STORE_NAME);

            store.delete(found.contentUrl).onerror = e => console.log(e);
            //yeah I should probably fix this up at some point...
            //just gonna assume /shrug

            let foundIndex = sessionData.findIndex(d => d.rssUrl == rssUrl && (d.title == title || d.guid == guid || d.contentUrl == contentUrl));
            if (foundIndex >= 0) {
                sessionData.splice(foundIndex, 1);
            }
            localStorage.setItem(SESSION_STORE_KEY, JSON.stringify(sessionData));
        } catch {
            return false;
        }
    }

    /**
     * 
     * @param {String} title 
     * @param {String} guid 
     * @param {String} contentUrl 
     * @param {String} date 
     * @param {String} description 
     * @param {Boolean} watched 
     * @param {String} rssUrl 
     * @param {Number} progress
     * @param {Number} totalDuration
     * @returns {DownloadedContent}
     */
    async download(title, guid, contentUrl, date, description, watched, rssUrl, progress, totalDuration) {
        let dl = null;
        try {
            dl = await axios({
                url: `rss/proxy/v2/downloadMedia?url=${encodeURIComponent(contentUrl)}&rssUrl=${encodeURIComponent(rssUrl)}`,
                method: 'GET',
                responseType: 'blob'
            });

            if (dl.status > 299) {
                return null;
            }
        } catch {
            return null;
        }


        let data = new DownloadContentData(title, guid, contentUrl, rssUrl, date, description, dl.data);

        try {

            let transaction = db.transaction(DB_STORE_NAME, 'readwrite');
            //transaction has oncomplete, onerror

            let store = transaction.objectStore(DB_STORE_NAME);
            store.put(data, contentUrl);

            let foundIndex = sessionData.findIndex(d => d.rssUrl == rssUrl && (d.title == title || d.guid == guid || d.contentUrl == contentUrl));
            if (foundIndex >= 0) {
                sessionData.splice(foundIndex, 1);
            }
            sessionData.push(new DownloadProgressData(title, guid, contentUrl, rssUrl, watched, progress, totalDuration));
            localStorage.setItem(SESSION_STORE_KEY, JSON.stringify(sessionData));
        } catch {
            return null
        }
        return data;
    }
}

export class DownloadListItem {
    /**
     * 
     * @param {String} title 
     * @param {String} guid 
     * @param {String} contentUrl 
     * @param {String} rssUrl 
     * @param {String} date 
     * @param {String} description 
     * @param {Boolean} watched
     * @param {Number} progress
     * @param {Number} totalDuration
     */
    constructor(title, guid, contentUrl, rssUrl, date, description, watched, progress, totalDuration) {
        this.title = title;
        this.guid = guid;
        this.contentUrl = contentUrl;
        this.rssUrl = rssUrl;
        this.date = date;
        this.description = description;
        this.watched = Boolean(watched);
        this.progress = progress;
        this.totalDuration = totalDuration;
    }
}

export class DownloadedContent {
    /**
     * 
     * @param {String} title 
     * @param {String} guid 
     * @param {String} contentUrl 
     * @param {String} rssUrl 
     * @param {String} date 
     * @param {String} description 
     * @param {Boolean} watched
     * @param {Blob} data 
     * @param {Number} progress
     * @param {Number} totalDuration
     */
    constructor(title, guid, contentUrl, rssUrl, date, description, watched, data, progress, totalDuration) {
        this.title = title;
        this.guid = guid;
        this.contentUrl = contentUrl;
        this.rssUrl = rssUrl;
        this.date = date;
        this.description = description;
        this.watched = Boolean(watched);
        this.data = data;
        this.progress = progress;
        this.totalDuration = totalDuration;
    }
}

//saved data in indexeddb (large, slow changing)
class DownloadContentData {
    /**
     * 
     * @param {String} title 
     * @param {String} guid 
     * @param {String} contentUrl 
     * @param {String} rssUrl 
     * @param {String} date 
     * @param {String} description 
     * @param {Blob} data 
     */
    constructor(title, guid, contentUrl, rssUrl, date, description, data) {
        this.title = title;
        this.guid = guid;
        this.contentUrl = contentUrl;
        this.rssUrl = rssUrl;
        this.date = date;
        this.description = description;
        this.data = data;
    }
}
//saved data in session store (volatile)
class DownloadProgressData {
    /**
     * 
     * @param {String} title 
     * @param {String} guid 
     * @param {String} contentUrl 
     * @param {String} rssUrl 
     * @param {Boolean} watched 
     * @param {Number} progress 
     * @param {Number} totalDuration
     */
    constructor(title, guid, contentUrl, rssUrl, watched, progress, totalDuration) {
        this.title = title;
        this.guid = guid;
        this.contentUrl = contentUrl;
        this.rssUrl = rssUrl;
        this.watched = watched;
        this.progress = progress;
        this.totalDuration = totalDuration;
    }
}


//let's do a singleton, that'll probable be fine?
/** @type {IDBDatabase} */
var db = null;
const dbOpenRequest = indexedDB.open(DB_NAME, DB_VERSION);

dbOpenRequest.onerror = e => {
    //error handling
}

dbOpenRequest.onsuccess = e => {
    db = dbOpenRequest.result;
}

dbOpenRequest.onupgradeneeded = e => {
    db = e.target.result;
    db.onError = e => {
        //handle initialization error
    }

    let objStore = db.createObjectStore(DB_STORE_NAME);

    objStore.createIndex('title', 'title', { unique: false });
    objStore.createIndex('guid', 'guid', { unique: true });
    objStore.createIndex('rssUrl', 'rssUrl', { unique: false });
}

/** @type {DownloadProgressData[]} */
var sessionData = [];
let sessionDataJson = localStorage.getItem(SESSION_STORE_KEY);
if (sessionDataJson) {
    let data = JSON.parse(sessionDataJson);
    sessionData = data?.map(d => new DownloadProgressData(d.title, d.guid, d.contentUrl, d.rssUrl, d.watched, d.progress, d.totalDuration)) || [];
}
