import VersionApi from './VersionApi.js'

const versionApi = new VersionApi();
const CLIENT_VERSION = process.appInfo.appVersion;

export default class VersionChecker {
    ensureCorrectVersion() {
        if (window.location.search)
            return;

        //if there's no version query parameter
        //we get the version from this client's build (would be cached)
        //compare against server version
        //if they don't match, redirect with versioned query parameter
        //using startswith cause dotnet likes 4 part assembly versions, but the release process only uses 3 parts

        versionApi.getVersion().then(v => {
            if(process.appInfo.appVersion && v && !v.startsWith(process.appInfo.appVersion)) {
                console.log('old version detected: ', CLIENT_VERSION, 'redirecting to newer version:', v);
                window.location = '?v=' + v + window.location.hash;
            }
        })
    }
}