import axios from 'axios';

export default class VersionApi {
    /**
     * GET /rss/api/v2/version
     * @returns {string}
     */
    async getVersion() {
        try {
            let response = await axios.get('rss/api/v2/version');
            if (response.data) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    }
}