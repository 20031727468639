<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="form-group text-left">
              <label>Please enter your username.</label>
              <div class="alert alert-danger" v-show="errorMessage">
                {{ errorMessage }}
              </div>
              <input
                type="text"
                class="form-control"
                name="usernameInput"
                v-model="usernameInputText"
                placeholder="username"
              />
              <input 
                type="password"
                class="form-control"
                name="secretInput"
                v-model="secretInputText"
                placeholder="password"
              />
            </div>
            <button
              type="button"
              class="btn btn-primary float-right"
              :class="{ disabled: isBusy }"
              v-on:click="loginButtonClicked()"
            >
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "../scripts/UserService";
import UserApi from "../scripts/UserApi";

const userService = new UserService();
const userApi = new UserApi();

export default {
  data() {
    return {
      errorMessage: "",
      usernameInputText: "",
      secretInputText: "",
      isBusy: false,
    };
  },
  mounted() {},
  methods: {
    async loginButtonClicked() {
      this.errorMessage = "";

      if (!this.usernameInputText || !this.secretInputText) {
        this.errorMessage = "username & password must be something";
        return;
      }

      this.isBusy = true;
      let success = await userApi.login(this.usernameInputText, this.secretInputText);
      if(success) {
        let user = await userApi.getUser();
        userService.setLoggedInUser(user.username);
        let redirect = this.$router.currentRoute?.query?.redirect;
        this.$router.push(redirect || "/");
      } else {
        this.errorMessage = "invalid user";
      }

      this.isBusy = false;
    },
  },
};
</script>

<style>
/* test */
</style>